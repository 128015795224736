










import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesDayPopup from '../../modules/rates/components/rates-day-popup.vue';

@Component({
    components: {
        RatesDayPopup,
    },
})

export default class RatesClusterPopup extends Vue {
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    @Prop({
        type: Number,
        required: true,
    })
    private hotelId!: number;

    @Prop({
        type: String,
        required: true,
    })
    private compsetId!: string;

    created(): void {
        this.clusterRatesService.switchCurrentHotel(this.hotelId);
        this.ratesFiltersService.setClusterCompetitors(this.hotelId);
    }

    onTriggerScan(day: Day) {
        this.clusterRatesService.triggerScan(day, this.compsetId);
    }

    beforeDestroy() {
        this.ratesFiltersService.resetRatesFilters();
    }
}

