
import { Inject } from 'inversify-props';
import { Component, Prop } from 'vue-property-decorator';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import { PriceScheme } from '@/modules/rates/interfaces/priceScheme.interface';

import RatesDayPopup, { ColumnData, TableData } from '@/modules/common/modules/rates/components/common-popup/rates-day-popup.vue';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import RatesPriceHistoryCommonService, {
    RatesPriceHistoryCommonServiceS,
} from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import PriceFilter from '@/modules/common/filters/price.filter';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';

interface ClusterTableData extends TableData {
    hotelId: number;
    price: number | string;
    priceType: string | null;
    roomName: string;
    hotelName: string;
    rank: number | string;
    losRestriction?: number;
    isCurrentHotel: boolean;
    link?: string;
}

@Component({
    extends: RatesDayPopup,
})
export default class ClusrerRatesDayPopup extends RatesDayPopup<ClusterTableData> {
    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Inject(RatesPriceHistoryCommonServiceS)
    private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Prop({
        type: Number,
        required: true,
    })
    public hotelId!: number;

    @Prop({
        type: String,
        required: true,
    })
    public compsetId!: string;

    private localPriceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;

    get tableData(): ClusterTableData[] {
        if (this.isLoading) return this.getDummyData();

        const { day, hotelId, compsetType } = this;
        const { localPriceShown } = this;
        const rooms = this.clusterRatesService.getAllHotelRooms(day, hotelId, true);

        if (!rooms) return [];

        const toTableData = ([hid, room]: [string, RatesDocumentItemModel | null]) => {
            const priceKey = (`${localPriceShown.toLowerCase()}Price`) as keyof PriceScheme;
            const price = room ? (room.price as PriceScheme)[priceKey] : -1;
            let priceType: string | null = null;

            const hotelName = false
                || this.clusterRatesService.getHotelName(+hid)
                || this.hotelsService.getHotelName(+hid);

            const roomName = room ? room.roomName : '-';
            const link = price !== PRICE.NA
                ? this.clusterRatesService.getHotelLink(day, hotelId, +hid)
                : null;

            let losRestriction: number | null = null;

            if (price && price !== PRICE.NA) {
                priceType = room ? room.priceType : null;
                losRestriction = this.clusterRatesService.getHotelLosRestriction(day, hotelId, +hid) || null;
            }

            return {
                hotelId: +hid,
                roomName,
                hotelName,
                price,
                priceType,
                rank: '',
                losRestriction,
                isCurrentHotel: +hid === +hotelId,
                isCompset: false,
                link,
            } as ClusterTableData;
        };

        const byPriceDescending = (
            a: ClusterTableData,
            b: ClusterTableData,
        ) => +b.price - +a.price;

        const compsetPrice = this.clusterRatesService.getCompsetPrice(day, hotelId, localPriceShown) || 0;
        const currency = this.clusterRatesService.getCurrency(hotelId);

        const competitiveRoom = {
            hotelId: -1,
            hotelName: String(this.$t(`filterSettings.compset_popup_table.${compsetType}`)),
            roomName: '-',
            price: compsetPrice,
            priceType: null,
            rank: '',
            isCurrentHotel: false,
            isCompset: true,
        };

        const data = Object
            .entries(rooms)
            .map(toTableData)
            .sort(byPriceDescending);

        const validPriceRooms = data
            .filter(room => room.price && room.price !== PRICE.NA);

        validPriceRooms
            .forEach((_, i, { length }) => {
                validPriceRooms[i].rank = String(length - i);
            });

        if (competitiveRoom.price) {
            data.push(competitiveRoom);
        }

        data.sort(byPriceDescending)
            .forEach((_, i) => {
                const room = data[i];

                switch (room.price) {
                    case PRICE.NA:
                        room.price = 'N/A';
                        room.roomName = '-';
                        break;

                    case PRICE.SOLD_OUT:
                        room.price = 'Sold out';
                        room.roomName = '-';
                        break;

                    default:
                        room.price = currency! + PriceFilter(room.price, 2);
                        break;
                }
            });

        return data;
    }

    get columns(): ColumnData<ClusterTableData>[] {
        const { priceType } = this.clusterRatesService;

        const cols = [
            {
                label: 'Price',
                field: 'price',
            },
            {
                label: '',
                field: 'losRestriction',
                component: (losRestriction: any) => ({
                    is: 'i',
                    class: !losRestriction ? '' : 'icon-los2',
                }),
            },
            {
                label: 'Rank',
                field: 'rank',
            },
            {
                label: 'Hotel Name',
                field: 'hotelName',
                width: '180px',

                component: (value: string, item: { link?: string }) => ({
                    is: item.link ? 'a' : 'span',
                    target: '_blank',
                    href: item.link,
                    text: this.cutString(value as string),
                    title: value,
                    class: item.link ? 'link' : '',
                }),
            },
            {
                width: '200px',
                label: 'Room Name',
                field: 'roomName',
            },
        ] as ColumnData<ClusterTableData>[];

        if (priceType === PRICE_TYPE.LOWEST) {
            cols.splice(1, 0, {
                width: '80px',
                label: 'Type',
                field: 'priceType',
            });
        }

        return cols;
    }

    get occupancy() {
        return this.clusterRatesService.getOccupancy(this.day);
    }

    get demand() {
        return this.clusterRatesService.getDemand(this.day);
    }

    get competitionPercent() {
        const { hotelId, day } = this;
        const percent = this.clusterRatesService
            .getCompetitionPercent(day, hotelId);

        return percent ? Math.round(percent * 100) : percent;
    }

    get dayColor() {
        const { hotelId, day } = this;
        const assessment = this.clusterRatesService.getCardAssessment(day, hotelId);

        let color = 'grey';

        switch (assessment) {
            case ASSESSMENT_TYPES.GOOD:
                color = 'green';
                break;
            case ASSESSMENT_TYPES.NORMAL:
                color = 'yellow';
                break;
            case ASSESSMENT_TYPES.BAD:
                color = 'red';
                break;
            default: break;
        }

        if (this.compsetDataType.isNA) {
            color = 'red';
        }

        return { [color]: true };
    }

    get compsetDataType() {
        const { day, hotelId } = this;

        return {
            isNA: this.clusterRatesService.isNa(day, hotelId),
            isSoldOut: this.clusterRatesService.isSoldOut(day, hotelId),
            isNoData: this.clusterRatesService.isNoData(day, hotelId),
        };
    }

    get scanButtonProps() {
        const { day, hotelId } = this;
        const mainCompset = this.clusterRatesService.getMainCompset(hotelId);

        if (!mainCompset || !mainCompset.checkinDates) return {};

        const lastScan = this.clusterRatesService.getUpdateDate(day, hotelId);

        return {
            lastScan,
            day,
            data: this.clusterRatesService.getHotel(hotelId),
            settings: this.clusterRatesService.currentSettings,
            showScanDate: true,
        };
    }

    set priceShown(value: PRICE_SHOWN) {
        this.localPriceShown = value;
    }

    get priceShown() {
        return this.localPriceShown;
    }

    get isTableDataAvailable() {
        return !this.clusterRatesService.isNoData(this.day, this.hotelId);
    }

    get isOutOfRange() {
        const { hotelId } = this;
        return this.clusterRatesService.isOutOfRange(hotelId);
    }

    get isScanAvailable() {
        return this.clusterRatesService.isScanAvailable(this.day);
    }

    get isLoading() {
        return this.clusterRatesService.isLoading;
    }

    get rawData() {
        const { hotelId } = this;

        return this.clusterRatesService.getMainCompset(hotelId) || {};
    }

    private getDummyData(): ClusterTableData[] {
        return Array
            .from({ length: 5 })
            .map(() => ({
                hotelId: -1,
                price: '999',
                priceType: '...',
                roomName: 'Sample room',
                hotelName: 'Sample hotel',
                rank: '22',
                isCurrentHotel: false,
                isCompset: false,
            }));
    }

    private cutString(str: string, threshold = 20) {
        if (str.length > threshold) {
            return `${str.substring(0, threshold)}...`;
        }

        return str;
    }

    openPriceHistory() {
        const compsetMain = this.clusterRatesService.getMainCompset(this.hotelId);
        const { currentSettings: settings } = this.clusterRatesService;

        this.ratesPriceHistoryCommonService.initRatesData(compsetMain, settings);

        const priceHistoryRoute = {
            name: `${this.$route.name}.price-history-popup`,
            params: {
                ...this.$route.params,
                historyDay: this.$route.params.day,
            },
        };

        this.$router.push(priceHistoryRoute);
    }

    onTriggerScan() {
        const { day, compsetId } = this;

        this.clusterRatesService
            .triggerScan(day, compsetId);
    }
}
